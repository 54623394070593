var rangeslider = require( 'ion-rangeslider' );

if (document.querySelector('.filter-box')) {

  var minval = 300;
  var maxval = 600;
  var $range = $("#range");

  $range.ionRangeSlider({
    type: "double",
    grid: false,
    min: 300,
    max: 600,
    from: 300,
    to: 600,
    onFinish: function(data) {

      var filters = getFilters();
    getAccomodations(filters);
  console.log('finish');
    }
  })

  $('.js-filtertype').on('click', function(event) {
    event.preventDefault();
    $(this).toggleClass('isOpen');
  });

  var url = Routing.generate("filter_accomodations");

  $('.filter-item').on('click', function(e) {
    e.preventDefault();

    $(this).parent().siblings().children().removeClass('applied');
    $(this).toggleClass("applied");

    var filters = getFilters();
    getAccomodations(filters);
  });

  $(".filter-checks").find("input[type='checkbox']").change(function() {
    $(this).toggleClass("applied");

    var filters = getFilters();
    getAccomodations(filters);
  });


}

// $(lowerSlider).on('input', function(event) {
//   event.preventDefault();
// });

function getFilters() {
  var criteria = $('.applied');
  var filters = [];

  $.map(criteria, function(filter) {
    if ($(filter).is(':checkbox')) {
      var key = $(filter).attr("name");
      var value = $(filter).val();
    } else {
      var key = $(filter).parent().parent().siblings('span').data("filter");
      var value = $(filter).data("value");
    }

    filters.push({
      key: key,
      value: value
    });
  });

  filters.push({
    key: "minPrice",
    value: $range.data("from")
  }, {
    key: "maxPrice",
    value: $range.data("to")
  });

  return filters;
}

function getAccomodations(filters) {
  $.ajax({
    type: "POST",
    url: url,
    data: {
      'filters': filters
    },
    success: function(data) {
      $("#accomodations").html(data);

      /* when document is ready */
      $(function() {
        /* initiate the plugin */
        $("div.holder").jPages({
          containerID: "itemContainer",
          perPage: 9,
          previous: "",
          next: "",
          startRange: 1,
          midRange: 1,
          endRange: 1


        });
      });

    }
  });
}
