require("./plugins/bootstrap.js");
require("./plugins/bootstrap-datepicker.js");
require("./plugins/jpages.min.js");
require("./plugins/nouislider.min.js");

require("./modules/forms.js");
require("./modules/gallery.js");
require("./modules/reviews.js");
require("./modules/nav.js");
require("./modules/filter.js");
require("./modules/popup.js");
require("./modules/slider.js");
require("./modules/fastbooker.js");
require("./modules/mobile-nav.js");

require("./modules/location.js");



var btn = $('.js-nav-toggle');
var mm = $('.js-mobile-nav');

btn.on('click', function(event) {
  event.preventDefault();
  mm.toggleClass('isVisible');
  $(this).toggleClass('isToggled');
$('html').css('overflow-x', 'hidden');

});

/* when document is ready */
  $(function(){
    /* initiate the plugin */
    $("div.holder").jPages({
        containerID  : "itemContainer",
        perPage      : 9,
        previous     : "",
        next         : ""
    });
});


var toggle = document.querySelector('.js-nav-toggle');
var menu = document.querySelector('.js-mnav-box');
var header = document.querySelector('.js-mmheader');

function setMenuDimensions() {
  var top = header.offsetHeight;
  menu.style.top = top + 'px';
  menu.style.height = (window.innerHeight - top) + 'px';
};

function toggleMenu(e) {
  e.preventDefault();
  menu.classList.toggle('isOpen');
  this.classList.toggle('isOpen');
  document.documentElement.classList.toggle('fixed');
};

setMenuDimensions();
toggle.addEventListener('click', toggleMenu);
window.addEventListener('resize', setMenuDimensions);

// var subnav = document.querySelectorAll('.mnav .menu_level_1');
// var subnavArr = Array.prototype.slice.call(subnav);

// subnavArr.forEach(function(item){
//   var toggle = document.createElement('span');
//   toggle.classList.add('js-msubnav-toggle');
//   toggle.addEventListener('click', function() {
//     this.parentNode.querySelector('.menu_level_1').classList.toggle('isOpen');
//     this.classList.toggle('isOpen');
//   });
//   var parent = item.parentNode;
//   parent.appendChild(toggle);
// });
