var gmaps = require( 'google-maps' );
gmaps.KEY = 'AIzaSyDFq2vrcPCb8Tv_ULC50QYdeyufE9IiMWU';

if ( $( '#map' ).length ) {
  ( function() {

    gmaps.load( function( google ) {
      var map;

      function initialize() {
        var glat = 51.211729;
        var glong = 3.225094;

        var mapOptions = {
          zoom: 15,
          scrollwheel: false,
          styles: [
            {
              stylers: [
                {
                  'saturation': '-100'
                }
              ]
            }
          ],
          center: new google.maps.LatLng( glat, glong )
        };





        map = new google.maps.Map( document.getElementById( 'map' ), mapOptions );

        var marker = new google.maps.Marker( {
          position: new google.maps.LatLng( glat, glong ),
          map: map
        } );
      }
      google.maps.event.addDomListener( window, 'load', initialize );
    } );
  } )();
}
