(function ($) {

var UIController = (function(){

  var initialText;

  function deleteError(el){
    var element =  document.getElementById('form__error-message');
    if (typeof(element) != 'undefined' && element !== null) {
      el.removeChild(element);
    }
  }

  function addBtnClass(btn, btnClass){
    btn.removeClass('isLoading').addClass(btnClass);
    setTimeout(function(){
      btn.removeClass(btnClass);
    }, 3000);
  }

  function resetReviewForm(){
    $('#rating').raty('score', 0);
    $("input[name='score']").val(null);
    $('#rating img').removeClass('selected');
    $('.rating-caption').text(initialText);
  }

  return {

    getData: function(el){
      var formdata = {
        routingTarget:  Routing.generate('site_frontend_ajax_call'),
        method: 'post',
        input: $(el).serializeArray()
      };
      return formdata;
    },

    getReviewData: function(el){
      var input = $(el).serializeArray();
      var formdata = {
        rating: input[0].value,
        name: input[1].value,
        email: input[2].value,
        message: input[3].value
      };
      return formdata;
    },

    handleSuccess: function(el, btn){
      deleteError(el);
      $(el)[0].reset();
      addBtnClass(btn, 'loadingSuccess');

      $('.js-form-succes').addClass('form__message--success');
      setTimeout(function(){
        $('.js-form-succes').removeClass('form__message--success');
      }, 3000);

      if($(el).hasClass('review-form')){
        resetReviewForm();
      }
    },

    handleError: function(r, el, btn){
      deleteError(el);
      addBtnClass(btn, 'loadingError');
      el.insertAdjacentHTML('beforeend', '<p class="form__message--error" >'+ r.message +'</p>');
    },

    beforeSend: function(btn){
      btn.addClass('isLoading');
    },

    setInitialText: function(){
      initialText = document.querySelector('.rating-caption').textContent;
    }

  };

})();

var formController = (function(){

  return {

    sendMail: function(data, callback, beforeSend){
      return $.ajax({
          method: data.method,
          url: data.routingTarget,
          beforeSend: beforeSend,
          data: {
            entity: 'contact',
            form: data.input
          },
          success: callback,
          error: callback
      });

    },
    sendRequestFormMail: function(data, callback, beforeSend){
      return $.ajax({
          method: data.method,
          url: data.routingTarget,
          beforeSend: beforeSend,
          data: {
            entity: 'request',
            form: data.input
          },
          success: callback,
          error: callback
      });

    },

    submitReview: function(data, callback, beforeSend){
      var url = Routing.generate('site_frontend_ajax_call_reviews');
      return $.ajax({
          type: "POST",
          dataType: 'json',
          url: url,
          beforeSend: beforeSend,
          data: data,
          success: callback,
          error: callback
      });
    }

  };

})();


var controller = (function(formController, UIController){

  function handleSubmit(e){

    e.preventDefault();
    var el = e.target;
    var btn = $(el).find('#submit');
    var data = UIController.getData(el);

    formController.sendMail(data, formCallback, UIController.beforeSend(btn));

    function formCallback(r){
      if (r.status){
        UIController.handleSuccess(el, btn);
      } else {
        UIController.handleError(r, el, btn);
      }
    }

  }

  function handleRequestFormSubmit(e){

    e.preventDefault();
    var el = e.target;
    var btn = $(el).find('#submit');
    var data = UIController.getData(el);

    formController.sendRequestFormMail(data, formCallback, UIController.beforeSend(btn));

    function formCallback(r){
      if (r.status){
        UIController.handleSuccess(el, btn);
      } else {
        UIController.handleError(r, el, btn);
      }
    }

  }

  function handleReviewSubmit(e){
    e.preventDefault();
    var el = e.target;
    var btn = $(el).find('#review__submit');
    var data = UIController.getReviewData(el);

    formController.submitReview(data, reviewCallback, UIController.beforeSend(btn));

    function reviewCallback(r){
      if (r.status === 'succes'){
        UIController.handleSuccess(el, btn);
      } else {
        UIController.handleError(r, el, btn);
      }
    }

  }

  return {
    init: function(){
      var form   = $('#main-contact-form');
      var requestForm   = $('#main-request-form');
      var reviewForm = $('.review-form');
      if (form.length) {
          form.on('submit', handleSubmit);
      }
      if (requestForm.length) {
          requestForm.on('submit', handleRequestFormSubmit);
      }
      if (reviewForm.length) {
        UIController.setInitialText();
          reviewForm.each(function () {
              $(this).on('submit', handleReviewSubmit);
          });
      }
    }
  };

})(formController, UIController);

controller.init();

})(jQuery);
