var magnificPopup = require("magnific-popup");

var $gallery = $('.js-gallery');
var $slider = $('.js-subslider');

$gallery.each(function() {
  $(this).magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true
    }
  });
});
$slider.magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true
    }
});
