( function() {

  var subItems = document.querySelectorAll( '.menu_level_1 .js-highlight' );
  var subItemsArr = Array.prototype.slice.call( subItems );
  subItemsArr.forEach( function( item ) {
    item.classList.remove( 'js-highlight' );
  } );

  var header = document.querySelector( '.js-header' );
  var items = header.querySelectorAll( '.js-highlight' );


  var activeItem = header.querySelector( '.active' );
  if ( activeItem == null ) {
    activeItem = items[ 0 ];
  }
  // if ( activeItem.parentNode.classList.contains( 'menu_level_1' ) ) {
  //   activeItem = header.querySelector( '.nav .current_ancestor' );
  // };

  var highlight = document.createElement( 'div' );

  highlight.classList.add( 'highlight' );
  header.appendChild( highlight );

  function highlightInit() {
    var activeCoords = activeItem.getBoundingClientRect();
    var initCoords = {
      width: activeCoords.width,
      left: activeCoords.left
    }
    highlight.classList.add( 'isVisible' );
    highlight.style.width = initCoords.width + 'px';
    highlight.style.transform = 'translateX(' + initCoords.left + 'px)';
  }

  function triggerHighlight() {

    var linkCoords = this.getBoundingClientRect();

    var coords = {
      width: linkCoords.width,
      left: linkCoords.left
    }

    highlight.style.width = coords.width + 'px';
    highlight.style.transform = 'translateX(' + coords.left + 'px)';

  };


  var itemsArr = Array.prototype.slice.call( items );
  itemsArr.forEach( function( item ) {
    item.addEventListener( 'mouseenter', triggerHighlight );
  } );


  if ( typeof( activeItem ) != 'undefined' && activeItem != null ) {
    header.addEventListener( 'mouseleave', function() {
      highlightInit();
    } );

    highlightInit();
  }


} )();



( function() {

  var bar = document.querySelector( '.js-bar' );
  if ( bar ) {

    var barItems = bar.querySelectorAll( '.js-highlight' );


    var activeItem = bar.querySelector( '.active' );
    if ( activeItem == null ) {
      activeItem = barItems[ 0 ];
    }

    var highlight = document.createElement( 'div' );

    highlight.classList.add( 'bar-highlight' );
    bar.appendChild( highlight );

    function highlightInit() {
      var activeItem = bar.querySelector( '.active' );

      var activeCoords = activeItem.getBoundingClientRect();

      var barCoords = bar.getBoundingClientRect();

      var bCoords = {
        left: barCoords.left
      };

      var initCoords = {
        width: activeCoords.width,
        left: activeCoords.left
      };

      var coord = initCoords.left - bCoords.left;
      highlight.classList.add( 'isVisible' );
      highlight.style.width = initCoords.width + 'px';
      highlight.style.transform = 'translateX(' + coord + 'px)';
    }

    function triggerHighlight() {

      var linkCoords = this.getBoundingClientRect();
      var barCoords = bar.getBoundingClientRect();

      var bCoords = {
        left: barCoords.left
      };

      var coords = {
        width: linkCoords.width,
        left: linkCoords.left
      };

      var coord = coords.left - bCoords.left;
      highlight.style.width = coords.width + 'px';
      highlight.style.transform = 'translateX(' + coord + 'px)';

    }


    var itemsArr = Array.prototype.slice.call( barItems );
    itemsArr.forEach( function( baritem ) {
      baritem.addEventListener( 'mouseenter', triggerHighlight );
    } );


    if ( typeof( activeItem ) != 'undefined' && activeItem != null ) {
      bar.addEventListener( 'mouseleave', function() {
        highlightInit();
      } );

      highlightInit();
    }
  }


} )();

$( '.floor-link' ).on( 'click', function( event ) {
  event.preventDefault();
  $( '.floor-link' ).removeClass( 'active' );
  $( this ).addClass( 'active' );
  showFloors();
} );

function showFloors() {
  var floor = $( '.floor-link.active' ).data( 'floor' );
  var floorplan = $( '.floor-link.active' ).data( 'floorname' );

  var currentFloor = $( '.floorplan' ).find( "[data-floor='" + floor + "']" );
  $( '.js-floor' ).removeClass( 'active' );
  currentFloor.addClass( 'active' );

  var currentPlan =  $('.js-floorlinks').find( "[data-floorname='" + floorplan + "']" );
  $('.js-floorplan').removeClass('active');
  currentPlan.addClass('active');
}

$('.js-floorlist').on('click', function(event) {
  event.preventDefault();
  $(this).next('.js-roomlist').toggleClass('active');
});
